import { BodyText } from 'components/common/Typography';
import { iconComponentResolver } from 'lib/contentful/componentResolver';
import { pinpointIconMap } from 'models/PinpointModel';
import { SerializedComponentItem } from 'types/contentful/SerializedComponentItem';

type Props = {
  entry: SerializedComponentItem;
};

const PinpointFeature: React.FC<Props> = ({ entry }) => {
  const Icon = iconComponentResolver(`pinpoint/${pinpointIconMap.get(entry.icon)}`);

  return (
    <div className="flex flex-row justify-start items-start md:flex-col">
      <div className="mb-4 mr-4">{Icon && <Icon className="shadow-md text-4xl md:text-5xl" />}</div>
      <div>
        <BodyText as="h3" bold color="primary" dark className="mb-2">
          {entry.primaryText}{' '}
        </BodyText>
        <BodyText dark>{entry.secondaryText}</BodyText>
      </div>
    </div>
  );
};

export { PinpointFeature };
