import { BodyText } from 'components/common/Typography';
import { Container } from 'components/layout/Container';
import { Markdown } from 'components/Markdown';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';
import { UIComponentProps } from 'types/contentful';

import { PinpointFeature } from './PinpointFeature';

type Props = UIComponentProps;

type CustomStylesProps = {
  backgroundClassName?: string;
};

export const useStyles = makeStyles<CustomStylesProps>()({
  containerOuter: (props) => [
    'relative',
    'bg-primaryBackgroundDark bg-no-repeat',
    {
      'bg-right': !props.backgroundClassName,
      'md:bg-image-homepage-section-pinpoint-lg':
        !props.backgroundClassName || !props.backgroundClassName.includes('bg-image')
    },
    props.backgroundClassName
  ],
  features: ['grid grid-cols-1 space-y-6 mt-16', 'md:grid-cols-5 md:space-x-6 md:space-y-0'],
  header: ['homepage-section-pinpoint-header [&>h2]:text-primaryTextDark'],
  subheader: ['md:w-5/12'],
  containerInner: ['py-16 md:py-24 px-1 md:px-4']
});

export const HomepageSectionPinpoint = ({ entry, items }: Props) => {
  const featureBullets = items.get('FeatureBullet');
  const [heading, subheading] = entry.richTextSections;
  const styles = useStyles({});

  return (
    <div className={styles.containerOuter}>
      <Container>
        <div className={styles.containerInner}>
          {/* Markdown already has H2 tag, nested heading element causes React hydration errors */}
          <div className={styles.header}>
            <Markdown markdown={heading.primaryText} />
          </div>
          <BodyText dark className={styles.subheader}>
            {subheading.primaryText}
          </BodyText>

          <div className={styles.features}>
            {featureBullets.map((bullet) => (
              <PinpointFeature entry={bullet} key={bullet.id} />
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};
